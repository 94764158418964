<template lang="pug">
    .bottom-menu(v-if="$store.getters['mobile-layout/showBottomMenu']")
        burger-icon.menu-icon(@clicked="$store.commit('mobile-layout/showUserMenu', true)")
        transition(name="fade")
            .transparent(v-if="$store.getters['search/showHeaderSearchBar']")
                button.button(@click="$store.commit('mobile-layout/showSearchEngine', true)")
                    img(src="@/assets/icon/search-icon.svg")
        router-link.more-icon(tag="img" :src="createIcon" to="/mobile/pick-create")


</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({

    data() {
        return {
            createIcon: require('@/assets/icon/create-icon.svg')
        }
    },

    components: {
        BurgerIcon: () =>
            import ("skillbase-component/src/components/common/svg-icon/BurgerIcon"),
    }

});
</script>

<style lang="scss" scoped>
.bottom-menu {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    height: 56px;
    background-color: white;
    width: 100vw;
    box-shadow: 0px -3px 9px 0px var(--shadow-color);

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .transparent {
        position: absolute;
        left: calc(50vw - 38px);
        top: -38px;
        width: 76px;
        height: 76px;
        background: linear-gradient(
                to top,
                var(--background-color),
                var(--background-color) 50%,
                transparent 1rem,
                transparent 100%
        );
        border-radius: 100vh;

        button.button {
            position: absolute;
            width: 56px;
            height: 56px;
            top: 10px;
            left: 10px;
            background: var(--primary-color);
            border-radius: 100vh;
            border: none;

            img {
                filter: var(--svg-filter-white-color);
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .menu-icon {
        position: absolute;
        left: 16px;
        height: 18px;
        top: 20px;
    }

    .more-icon {
        position: absolute;
        height: 24px;
        right: 16px;
        top: 16px;
    }
}
</style>